<template>
	<div>
		<transition name="el-zoom-in-center">
			<keep-alive>
				<router-view></router-view>
			</keep-alive>
		</transition>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				
			}
		},
		components: {
			
		},
		methods:{
			
		},
		computed:{
			
		},
		created(){
			
		}
	}
</script>

<style lang="scss">
	
</style>
